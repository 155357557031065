export const getAmountByCredit = plan => {
	if (!plan) {
		return;
	}

	return plan.baseAmount / plan.credits;
};

export const getDiscount = (plan, cheapestPlan) => {
	if (!plan || !cheapestPlan) {
		return 0;
	}

	const mainAmountByCredit = getAmountByCredit(plan);
	const cheapestAmountByCredit = getAmountByCredit(cheapestPlan);

	return Math.round((mainAmountByCredit * 100) / cheapestAmountByCredit);
};

export const getCheapestPlan = (prices = []) => {
	const noTrialActivePrices =
		prices?.filter(item => item.name !== 'trial' && item.active) || [];

	let cheapestPlan = noTrialActivePrices[0];

	for (const plan of noTrialActivePrices) {
		if (plan.amount < cheapestPlan.amount) {
			cheapestPlan = plan;
		}
	}

	return cheapestPlan;
};
