import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import schemas from '@/validations/index.js';
import { useCallback, useEffect, useMemo } from 'react';
import userModel from '@/models/user';
import billingModel from '@/models/billing';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthMe } from '@/store/auth/selectors.js';
import { fetchMe } from '@/store/auth/thunks.js';
import { TAX_PAYER_TYPE } from '@/config.js';
import {
	needDisplayNamesPolyfill,
	useLanguage,
	useLoadPolyfill
} from '@/hooks';
import { requiresVat } from '@/utils/billing';
import { setRelevantBillingData } from '@/store/checkout';
import { showError, showSuccess } from '@/utils';

import countriesIso from './countries-iso';

export function useChangePassword() {
	const { t } = useTranslation();
	const { control, formState, getValues, handleSubmit } = useForm({
		resolver: yupResolver(schemas.changePassword()),
		mode: 'onChange'
	});

	const onSubmit = useCallback(
		async function () {
			const { password } = getValues();
			await userModel.update({ password });
			showSuccess(t('myAccount.changePassword.success'));
		},
		[getValues]
	);

	return {
		control,
		onSubmitEnabled: formState.isValid && !formState.isSubmitting,
		onSubmit: handleSubmit(onSubmit)
	};
}

export function useUpdateContactInformation() {
	const dispatch = useDispatch();
	const authMe = useSelector(useAuthMe);
	const { t } = useTranslation();
	const { firstName, lastName, email, city } = authMe.data ?? {};
	const { control, formState, getValues, handleSubmit } = useForm({
		resolver: yupResolver(schemas.contactInformation()),
		mode: 'onChange',
		defaultValues: {
			firstName,
			lastName,
			email,
			city
		}
	});

	const onSubmit = useCallback(
		async function () {
			try {
				const values = getValues();
				await userModel.update(values);
				dispatch(fetchMe());
				showSuccess(t('myAccount.contactInformation.success'));
			} catch (error) {
				showError(error);
			}
		},
		[getValues, dispatch, fetchMe]
	);

	return {
		control,
		onSubmitEnabled: formState.isValid && !formState.isSubmitting,
		onSubmit: handleSubmit(onSubmit)
	};
}

export function useUpdateTaxInformation({ onSuccess } = {}) {
	const dispatch = useDispatch();
	const authMe = useSelector(useAuthMe);
	const { t } = useTranslation();
	const {
		taxInformation,
		country: baseCountry,
		email: userEmail
	} = authMe.data ?? {};

	const {
		type = TAX_PAYER_TYPE.PRIVATE,
		companyName,
		country,
		taxId,
		vatnumber,
		firstName,
		lastName,
		address,
		zipCode,
		city,
		email
	} = taxInformation ?? {};

	const {
		control,
		formState,
		getValues,
		watch,
		register,
		unregister,
		handleSubmit
	} = useForm({
		resolver: yupResolver(schemas.taxInformation({ t })),
		mode: 'onChange',
		defaultValues: {
			type,
			companyName,
			country: country || baseCountry || '',
			taxId,
			vatnumber,
			firstName,
			lastName,
			address,
			zipCode,
			city,
			email: email || userEmail || ''
		}
	});

	const watchTaxPayerType = watch('type');
	const watchCountry = watch('country');
	const watchType = watch('type');
	const watchEmail = watch('email');

	useEffect(() => {
		if (watchTaxPayerType === TAX_PAYER_TYPE.COMPANY) {
			register('companyName');
			register('vatnumber');
		} else {
			unregister('companyName');
			unregister('vatnumber');
		}
	}, [register, unregister, watchTaxPayerType]);

	const onSubmit = useCallback(
		async function (values) {
			try {
				if (requiresVat(values)) {
					values.taxId = null;
				} else {
					values.vatnumber = null;
				}

				await billingModel.updateTaxInformation(values);
				dispatch(fetchMe());

				if (onSuccess) {
					onSuccess();
				} else {
					showSuccess(t('myAccount.taxInformation.success'));
				}
			} catch (error) {
				showError(error);
			}
		},
		[getValues, dispatch, fetchMe]
	);

	useEffect(() => {
		dispatch(
			setRelevantBillingData({
				country: watchCountry,
				accountType: watchType,
				email: watchEmail || email || userEmail
			})
		);
	}, [watchCountry, watchType, watchEmail]);

	return {
		control,
		onSubmit: handleSubmit(onSubmit),
		isSubmitting: formState.isSubmitting,
		showCompanyFields: watchTaxPayerType === TAX_PAYER_TYPE.COMPANY,
		hasVAT: requiresVat({
			country: watchCountry,
			type: watchTaxPayerType
		}),
		country: watchCountry
	};
}

export function useCountryOptions() {
	const language = useLanguage();
	const polyfillLocaleLoaded = useLoadPolyfill(language);

	return useMemo(() => {
		const collator = new Intl.Collator(language);
		const needPolyfill = needDisplayNamesPolyfill(language);
		const displayName = needPolyfill
			? null
			: new Intl.DisplayNames([language], { type: 'region' });

		return countriesIso
			.map(code => ({
				value: code,
				label: displayName?.of(code.toUpperCase()) || code
			}))
			.sort((a, b) => collator.compare(a.label, b.label));
	}, [language, polyfillLocaleLoaded]);
}
