import { useMemo } from 'react';
import {
	needDisplayNamesPolyfill,
	useLanguage,
	useLoadPolyfill
} from '@/hooks';
import { supportedLanguages } from '@/i18next';
import { capitalize } from '@/utils';

export function useLanguageOptions() {
	const language = useLanguage();
	const polyfillLocaleLoaded = useLoadPolyfill(language);

	return useMemo(() => {
		if (needDisplayNamesPolyfill(language)) {
			return [];
		}

		const displayName = new Intl.DisplayNames([language], {
			type: 'language'
		});

		return supportedLanguages.map(lng => ({
			value: lng,
			label: capitalize(displayName.of(lng) || '')
		}));
	}, [language, polyfillLocaleLoaded]);
}

export function useDisplayLanguage() {
	const language = useLanguage();
	const polyfillLocaleLoaded = useLoadPolyfill(language);

	const displayName = useMemo(() => {
		if (needDisplayNamesPolyfill(language)) {
			return {
				of: () => ''
			};
		}
		return new Intl.DisplayNames([language], {
			type: 'language'
		});
	}, [language, polyfillLocaleLoaded]);

	return value => capitalize(displayName.of(value) || '');
}
